import { Box, BoxProps, Flex } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import Container from '@/components/shared/Container';
import Divider from '@/components/shared/Divider';
import { SortFields } from '@/helpers/sortHelpers';
import TableHeaderText from '@/components/shared/Table/TableHeaderText';

export interface TableHeaderTooltip {
  text: string;
  title: string;
}

interface Props extends BoxProps {
  children: ReactNode;
  columnAlignments: ('left' | 'right')[];
  columnLabels: { label: string; sortField: SortFields; tooltip?: TableHeaderTooltip }[];
  columnWidths: number[];
  minW?: string;
}

const Table: React.FC<Props> = ({
  children,
  columnWidths,
  columnLabels,
  columnAlignments,
  minW = '48em',
  ...boxProps
}: Props) => {
  return (
    <Container mt={6} p={0} overflowY="hidden" overflowX="auto" {...boxProps}>
      <Flex px={6} py={4} minW={minW}>
        {columnLabels.map(({ label, sortField, tooltip }, index) => {
          return (
            <Box key={index} flex={columnWidths[index]}>
              <TableHeaderText
                tooltip={tooltip}
                text={label}
                sortField={sortField}
                textAlign={columnAlignments[index]}
              />
            </Box>
          );
        })}
      </Flex>
      <Divider minW={minW} />
      <Box minW={minW} position="relative">
        {children}
      </Box>
    </Container>
  );
};
export default Table;
