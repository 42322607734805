import { Box, Flex, Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react';
import { Market, Platform, PositionTypes } from '@/types';
import React, { ReactNode } from 'react';

import PlatformLogo from '../shared/PlatformLogo';
import Text from '@/components/shared/Text';
import TriggerCardLink from '../protection/TriggerCardLink';
import TriggerCardRates from '../protection/TriggerCardRates';
import TriggerCreatorLogo from '../shared/TriggerCreatorLogo';
import { calculatePositionStats } from '@/helpers/positionHelpers';
import { etherscanAddressUrl } from '@/helpers/activityItemHelpers';
import { useWeb3React } from '@web3-react/core';

interface Props {
  children: ReactNode;
  market: Market;
  platform: Platform;
  positionType: PositionTypes;
}

const TriggerPopover: React.FC<Props> = ({ children, market, platform, positionType }: Props) => {
  const { chainId } = useWeb3React();

  const { trigger } = market;
  const { creator } = trigger;

  const {
    formattedBorrowRate,
    formattedInvestRate,
    formattedNetRate,
    formattedSupplyRate,
    netRate,
  } = calculatePositionStats(null, market);

  return (
    <Popover trigger="hover">
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent cursor="pointer">
          <PopoverBody m={0} p={0}>
            <Box m={4}>
              <Flex mb={4} alignItems="center">
                <PlatformLogo boxSize={5} platform={platform} />
                <Text ml={2} fontWeight="600" fontSize="md">
                  {platform?.name} Protection
                </Text>
              </Flex>

              <Flex justifyContent="space-between" alignItems="flex-start">
                <Text mb={2} fontWeight="600" fontSize="sm" color="gray.500">
                  {trigger.name} Details
                </Text>

                <TriggerCardLink href={etherscanAddressUrl(trigger.address, chainId, '#code')}>More</TriggerCardLink>
              </Flex>

              <Text mb={6} fontSize="sm">
                {trigger.description}
              </Text>

              <Flex alignItems="flex-start" justifyContent="space-between">
                <TriggerCardRates
                  formattedBorrowRate={formattedBorrowRate}
                  formattedInvestRate={formattedInvestRate}
                  formattedNetRate={formattedNetRate}
                  formattedSupplyRate={formattedSupplyRate}
                  netRate={netRate}
                  positionType={positionType}
                />

                <TriggerCardLink href={etherscanAddressUrl(market.interestRateModelAddress, chainId, '#code')}>
                  View
                </TriggerCardLink>
              </Flex>
            </Box>
            {creator && (
              <Flex
                borderBottomRadius="6px"
                py={3}
                px={4}
                justifyContent="space-between"
                alignItems="center"
                backgroundColor="gray.50"
              >
                <Flex alignItems="center">
                  <TriggerCreatorLogo boxSize={3} triggerCreator={creator} />
                  <Text fontSize="xs" color="gray.500" ml={2}>
                    Created by {creator?.name}
                  </Text>
                </Flex>
              </Flex>
            )}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
export default TriggerPopover;
