import { ETH_TOKEN_CONTRACT_ADDRESS } from '@/constants';
import { Market } from '@/types';
import { formatUsdAmount } from './formatHelpers';

export const findEthMarket = (markets: Market[]): Market => {
  return markets.find((market) => market.underlyingAddress === ETH_TOKEN_CONTRACT_ADDRESS);
};

export const calculateAvailableToBorrow = (
  market: Market,
): { availableUnderlying: number; availableUsd: number; formattedAvailableUsd: string } => {
  const borrowCapAvailable = market.borrowCap - market.totalBorrows;
  const availableUnderlying = market.borrowCap != null ? Math.min(market.cash, borrowCapAvailable) : market.cash;
  const zeroBoundedAvailableUnderlying = Math.max(availableUnderlying, 0);

  const availableUsd = zeroBoundedAvailableUnderlying * market.underlyingPriceUSD;
  const formattedAvailableUsd = formatUsdAmount(availableUsd, '$0a');

  return { availableUnderlying, availableUsd, formattedAvailableUsd };
};

export const calculateMarketStats = (market: Market): { formattedMarketSizeUsd: string } => {
  const marketSize = calculateMarketSize(market);

  const marketSizeUsd = marketSize * market.underlyingPriceUSD;

  const formattedMarketSizeUsd = formatUsdAmount(marketSizeUsd, '$0a');

  return { formattedMarketSizeUsd };
};

export const calculateMarketSize = (market: Market): number => {
  if (market.trigger?.triggered) {
    return 0;
  } else {
    return market.totalSupply * market.exchangeRate;
  }
};
