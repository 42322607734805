import { Box, Flex, Icon, useTheme } from '@chakra-ui/react';
import { Market, PositionTypes } from '@/types';
import { calculateAvailableToBorrow, calculateMarketStats } from '@/helpers/marketHelpers';

import { AiFillCaretDown } from 'react-icons/ai';
import Link from '../shared/Link';
import { PLATFORMS } from '@/constants';
import PlatformLogoAndName from '../invest/PlatformLogoAndName';
import React from 'react';
import Text from '@/components/shared/Text';
import TriggerPopover from '@/components/popovers/TriggerPopover';
import { findDefaultPlatformByTrigger } from '@/helpers/findHelpers';

interface Props {
  flex: number;
  market: Market;
  positionType: PositionTypes;
  relatedMarkets?: Market[];
  setExpandedDefaultMarketIds?: React.Dispatch<React.SetStateAction<any[]>>;
  showAvailableToBorrow?: boolean;
  showMarketSize?: boolean;
  showPlatform?: boolean;
}

const TriggerRowCell: React.FC<Props> = ({
  flex,
  market,
  positionType,
  relatedMarkets,
  setExpandedDefaultMarketIds,
  showAvailableToBorrow,
  showMarketSize,
  showPlatform = true,
}: Props) => {
  const theme = useTheme();
  const { trigger } = market;

  const { formattedMarketSizeUsd } = calculateMarketStats(market);

  const platform = findDefaultPlatformByTrigger(PLATFORMS, trigger);
  const numRelatedMarkets = relatedMarkets ? relatedMarkets.length - 1 : 0;

  const { formattedAvailableUsd } = calculateAvailableToBorrow(market);

  const onClick = (e) => {
    e.stopPropagation();
    setExpandedDefaultMarketIds((marketIds) => [...marketIds, market.id]);
  };

  return (
    <Box flex={flex} alignItems="center">
      {showPlatform && <PlatformLogoAndName platform={platform} />}
      <Flex alignItems="baseline">
        <TriggerPopover market={market} platform={platform} positionType={positionType}>
          <a>
            {/* Need to wrap contents of trigger popover with button or a */}
            <Text
              pb="3px"
              color="gray.500"
              cursor="pointer"
              fontSize="sm"
              fontWeight={showMarketSize ? '600' : '400'}
              noOfLines={1}
              style={{ textDecorationColor: theme.colors.gray[300], textUnderlineOffset: '4px' }}
              textDecoration="underline dashed"
            >
              {numRelatedMarkets > 0 ? 'Default Protection' : trigger.name}
            </Text>
          </a>
        </TriggerPopover>
      </Flex>

      {numRelatedMarkets > 0 && (
        <Flex alignItems="center">
          <Link fontWeight={showMarketSize ? '600' : '400'} onClick={onClick} mt="6px" fontSize="sm">
            {numRelatedMarkets} other protection{numRelatedMarkets > 1 ? 's' : ''}
          </Link>
          <Icon ml={2} mt="2px" as={AiFillCaretDown} boxSize="9px" color="gray.500" />
        </Flex>
      )}

      {showMarketSize && (
        <Text mt={1} fontSize="sm" color="gray.400">
          {formattedMarketSizeUsd} Market Size
        </Text>
      )}

      {showAvailableToBorrow && (
        <Text mt={1} fontSize="sm" color="gray.400">
          {formattedAvailableUsd} Available
        </Text>
      )}
    </Box>
  );
};
export default TriggerRowCell;
