import { BoxProps, Flex, Progress } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import { motion } from 'framer-motion';

interface Props extends BoxProps {
  children: ReactNode;
  isPending?: boolean;
}

const TableRow: React.FC<Props> = ({ children, isPending = false, ...props }: Props) => {
  const content = (
    <Flex cursor="pointer" _hover={{ backgroundColor: 'gray.50' }} {...props}>
      {children}
    </Flex>
  );

  if (isPending) {
    return (
      <motion.div
        animate={{ opacity: [0.9, 0.5] }}
        style={{ position: 'relative', overflow: 'hidden' }}
        transition={{
          duration: 1,
          repeatType: 'reverse',
          repeat: Infinity,
        }}
      >
        <Progress
          position="relative"
          right="98px"
          top="60px"
          width="200px"
          size="xs"
          isIndeterminate
          colorScheme="gray"
          transform="rotate(90deg)"
        />

        {content}
      </motion.div>
    );
  }

  return content;
};
export default TableRow;
