import React, { ReactNode } from 'react';

import { Box } from '@chakra-ui/react';
import Text from '@/components/shared/Text';

interface Props {
  body?: string;
  button?: ReactNode;
  title: string;
}

const TableNullState: React.FC<Props> = ({ body, title }: Props) => {
  return (
    <Box p="6rem">
      <Text fontSize="lg" fontWeight="600" textAlign="center" color="gray.500">
        {title}
      </Text>
      {body && (
        <Text mt="1" fontSize="sm" textAlign="center" color="gray.500">
          {body}
        </Text>
      )}
    </Box>
  );
};
export default TableNullState;
