import { NextRouter, useRouter } from 'next/router';
import { SortFields, SortOrder } from '@/helpers/sortHelpers';

export const setSortFieldAndOrder = (router: NextRouter, sortField: SortFields): void => {
  const currentSortBy = router.query.sortBy;

  if (currentSortBy) {
    //   Need to cast as unknown to force query parameter into enum
    const currentSortOrder = (router.query.sortOrder as unknown) as SortOrder;
    const newSortOrder = toggledSortOrder(currentSortOrder);

    const newState = { ...router.query, sortBy: sortField, sortOrder: newSortOrder };

    router.push({ query: newState }, undefined, { shallow: true });
  } else {
    const newState = { ...router.query, sortBy: sortField, sortOrder: SortOrder.Desc };

    router.push({ query: newState }, undefined, { shallow: true });
  }
};

export const useSortRouterParams = (): {
  sortBy: SortFields;
  sortOrder: SortOrder;
} => {
  const router = useRouter();

  const { sortBy, sortOrder } = router.query;

  const sortByEnum = sortBy as SortFields;
  const sortOrderEnum = sortOrder as SortOrder;

  return {
    sortBy: sortByEnum,
    sortOrder: sortOrderEnum,
  };
};

const toggledSortOrder = (sortOrder: SortOrder): SortOrder => {
  if (sortOrder === SortOrder.Desc) return SortOrder.Asc;
  if (sortOrder === SortOrder.Asc) return SortOrder.Desc;
};
