import { Flex } from '@chakra-ui/react';
import { Platform } from '@/types';
import PlatformLogo from '../shared/PlatformLogo';
import React from 'react';
import Text from '@/components/shared/Text';

interface Props {
  color?: string;
  fontWeight?: string;
  platform: Platform;
}

const PlatformLogoAndName: React.FC<Props> = ({ platform, color, fontWeight = '600' }: Props) => {
  return (
    <Flex alignItems="center" mb="3px">
      <PlatformLogo platform={platform} boxSize={3} />
      <Text color={color} fontSize="sm" ml={2} fontWeight={fontWeight}>
        {platform?.name}
      </Text>
    </Flex>
  );
};
export default PlatformLogoAndName;
