import { Box, Flex, Icon, Tooltip, useTheme } from '@chakra-ui/react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdUnfoldMore } from 'react-icons/md';
import { SortFields, SortOrder } from '@/helpers/sortHelpers';
import { setSortFieldAndOrder, useSortRouterParams } from '@/hooks/useSortRouterParams';

import React from 'react';
import { TableHeaderTooltip } from './Table';
import Text from '@/components/shared/Text';
import { useRouter } from 'next/router';

interface Props {
  textAlign: 'left' | 'right';
  text: string;
  tooltip: TableHeaderTooltip;
  sortField: SortFields;
}

const TableHeaderText: React.FC<Props> = ({ sortField, text, textAlign, tooltip }: Props) => {
  const router = useRouter();
  const theme = useTheme();

  const onClick = () => setSortFieldAndOrder(router, sortField);
  const { sortBy, sortOrder } = useSortRouterParams();

  return (
    <Flex
      cursor={sortField ? 'pointer' : null}
      onClick={sortField ? onClick : null}
      alignItems="center"
      justifyContent={textAlign === 'right' ? 'flex-end' : 'flex-start'}
    >
      <Tooltip
        aria-label={tooltip?.text}
        arrowShadowColor="white"
        bg="white"
        borderRadius="lg"
        color={theme.colors.gray[700]}
        hasArrow
        fontWeight="400"
        label={tooltip?.text}
        marginBottom={1}
        padding={4}
        placement="top"
      >
        <Box>
          <Text color="gray.500" fontSize="sm" fontWeight="600" textAlign={textAlign}>
            {text}
          </Text>
        </Box>
      </Tooltip>
      {text != '' && sortField != null && selectIcon(sortOrder, sortBy === sortField)}
    </Flex>
  );
};

const selectIcon = (sortOrder: SortOrder, isActiveSortField: boolean) => {
  if (!isActiveSortField) {
    return <Icon ml={2} mr={-1} as={MdUnfoldMore} boxSize="12px" color="gray.500" />;
  }

  if (sortOrder === SortOrder.Desc) {
    return <Icon ml={2} mr={-1} as={MdKeyboardArrowDown} boxSize="12px" color="gray.500" />;
  } else {
    return <Icon ml={2} mr={-1} as={MdKeyboardArrowUp} boxSize="12px" color="gray.500" />;
  }
};

export default TableHeaderText;
