import { Account, Market, Platform } from '@/types';
import { findDefaultPlatformByTrigger, findPlatformsByTrigger, findPositionByMarketId } from '@/helpers/findHelpers';

import { MIN_POSITION_BALANCE } from './positionHelpers';
import { PLATFORMS } from '@/constants';
import { calculateMarketSize } from './marketHelpers';

interface PlatformUnderlyingPairMarkets {
  allMarkets: Market[];
  defaultMarket: Market;
}

interface SortParameters {
  field?: SortFields;
  order: SortOrder;
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export enum SortFields {
  BorrowBalance = 'borrowBalance',
  BorrowingPower = 'borrowingPower',
  BorrowInterestAccrued = 'borrowInterestAccrued',
  BorrowRate = 'borrowRate',
  InvestBalance = 'investBalance',
  InvestRate = 'investRate',
  MarketSize = 'marketSize',
  Name = 'name',
  NetRate = 'netRate',
  ProtectedAgainst = 'protectedAgainst',
  SupplyBalance = 'supplyBalance',
  SupplyInterestAccrued = 'supplyInterestAccrued',
  SupplyRate = 'supplyRate',
}

interface SortedMarketData {
  activeMarkets: Market[];
  defaultInactiveMarkets: Market[];
  hasActiveMarkets: boolean;
  hasMarkets: boolean;
  inactiveMarkets: Market[];
  platformUnderlyingPairMarkets: { [key: string]: PlatformUnderlyingPairMarkets };
}

export const sortMarkets = (
  markets: Market[],
  account: Account | null,
  balanceFields: ('supplyBalanceUnderlying' | 'borrowBalanceUnderlying' | 'investBalanceUnderlying')[],
  sortParameters?: SortParameters,
): SortedMarketData => {
  const activeMarkets = markets.filter((market) => {
    return account?.positions?.find((position) => {
      const marketMatchesPosition = market.id === position.marketId;
      const atLeastOneBalanceFieldHasBalance = balanceFields.find(
        (field) => position[field] * market.underlyingPriceUSD > MIN_POSITION_BALANCE,
      );

      return marketMatchesPosition && atLeastOneBalanceFieldHasBalance;
    });
  });

  const inactiveMarkets = markets.filter((market) => {
    return !activeMarkets.includes(market);
  });

  const platformUnderlyingPairMarkets: { [key: string]: PlatformUnderlyingPairMarkets } = inactiveMarkets.reduce(
    (acc, market) => {
      const platforms = findPlatformsByTrigger(PLATFORMS, market.trigger);
      const marketSize = calculateMarketSize(market);

      platforms.forEach((platform) => {
        const platformUnderlyingPair = platformUnderlyingPairKey(market, platform);

        const existingEntry = acc[platformUnderlyingPair];

        if (existingEntry == null) {
          acc[platformUnderlyingPair] = { defaultMarket: market, allMarkets: [market] };
        } else {
          const allMarkets = sortBy([...existingEntry.allMarkets, market], account, sortParameters);
          if (marketSize > calculateMarketSize(existingEntry.defaultMarket)) {
            acc[platformUnderlyingPair] = { defaultMarket: market, allMarkets };
          } else {
            acc[platformUnderlyingPair] = { ...existingEntry, allMarkets };
          }
        }
      });

      return acc;
    },
    {},
  );

  const defaultMarkets = Object.entries(platformUnderlyingPairMarkets).map(([, value]) => {
    return value.defaultMarket;
  });

  const hasActiveMarkets = activeMarkets.length > 0;

  return {
    activeMarkets: sortBy(activeMarkets, account, sortParameters),
    defaultInactiveMarkets: sortBy(defaultMarkets, account, sortParameters),
    hasActiveMarkets,
    hasMarkets: activeMarkets.length !== 0 || inactiveMarkets.length !== 0,
    inactiveMarkets: sortBy(inactiveMarkets, account, sortParameters),
    platformUnderlyingPairMarkets,
  };
};

const platformUnderlyingPairKey = (market: Market, platform: Platform) => {
  return `${platform.id}-${market.underlyingAddress}`;
};

const numberOperators = {
  [SortOrder.Asc]: (a, b) => a - b,
  [SortOrder.Desc]: (a, b) => b - a,
};

const stringOperators = {
  [SortOrder.Asc]: (a, b) => a.localeCompare(b),
  [SortOrder.Desc]: (a, b) => b.localeCompare(a),
};

const sortBy = (markets: Market[], account: Account | null, sortParameters?: SortParameters): Market[] => {
  if (sortParameters == null || sortParameters.field == null) return markets;

  return markets.sort((marketA, marketB) => {
    const a = sortFieldValue(marketA, sortParameters.field, account);
    const b = sortFieldValue(marketB, sortParameters.field, account);

    if (isString(a)) {
      return stringOperators[sortParameters.order](a, b);
    } else {
      return numberOperators[sortParameters.order](a, b);
    }
  });
};

const isString = (value: any) => {
  return typeof value === 'string' || value instanceof String;
};

const sortFieldValue = (market: Market, fieldName: string, account: Account | null) => {
  switch (fieldName) {
    case 'protectedAgainst': {
      const trigger = findDefaultPlatformByTrigger(PLATFORMS, market.trigger);
      return trigger.name;
    }
    case 'marketSize': {
      return calculateMarketSize(market) * market.underlyingPriceUSD;
    }
    case 'borrowInterestAccrued': {
      const position = findPositionByMarketId(account, market.id);
      if (position == null) return 0;

      return position.lifetimeSupplyInterestAccrued * market.underlyingPriceUSD;
    }
    case 'supplyInterestAccrued': {
      const position = findPositionByMarketId(account, market.id);
      if (position == null) return 0;

      return position.lifetimeBorrowInterestAccrued * market.underlyingPriceUSD;
    }
    case 'borrowBalance': {
      const position = findPositionByMarketId(account, market.id);
      if (position == null) return 0;

      return position.borrowBalanceUnderlying * market.underlyingPriceUSD;
    }
    case 'supplyBalance': {
      const position = findPositionByMarketId(account, market.id);
      if (position == null) return 0;

      return position.supplyBalanceUnderlying * market.underlyingPriceUSD;
    }
    default: {
      return market[fieldName];
    }
  }
};
